
import { defineComponent, reactive, ref, onMounted, watch,toRefs,onUnmounted,watchEffect } from "vue";
import { useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import { VMoney } from "v-money";
import { ContentLoader } from "vue-content-loader";
import Popover from "./components/Popover.vue";
import moment from "moment";
import {
  getView,
  getStore,
  getcidadeestado,
  categoriaOs,
  checkConnection
} from "@/services/Concessionaria";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Cropper from "cropperjs";
import { Modal } from "bootstrap";
import "cropperjs/dist/cropper.css";
import { hideModal } from "@/core/helpers/dom";
import { ListaCategoriaOS } from "@/models/ConcessionariaModel";
import UltilChanges from '@/composables/UtilChanges'
import useUtilChangesConcessionaria  from './composables/Ultil'

import { 
  modeloAgenda,
  horarios,
  estados,
  diasSegurancaEstoque,
  numeroParcelasFabrica,
  urlintegracao,
  buscaplaca,
  cardapio,
  modelosCardapio,
  precoosagregados,
  descontoLiberado,
  qualidadeFoto,
  qualidadeVideo,
  selectOptionEmailOS,
  selectOptionsIntegração,
  abrirPreOrdemModelos
} from "./mockedData"

interface Select {
  text: string,
  value: number 
}

interface SelectModeloOs {
  text: string,
  value: string | null 
}

interface SelectDms extends Select {
  imprimirOs : number 
}

interface Horario extends Select {
  disabled : boolean 
}

export default defineComponent({
  name: "cadastros/concessionaria/add",
  components: {
    ContentLoader,
    Popover
  },
  directives: {
    money: VMoney,
  },
  emits: ["addTask", "editTask", "success"],
  setup(props, { emit }) {
    const imgInicial = ref(
      "https://storagecamphml.blob.core.windows.net/concessionarias/logo_img/2023/06/1/1687542222vbodxj.jpg" 
    );
    const imageSrc:any = ref(null);
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if(event?.target?.result){ 
        imageSrc.value = event.target.result;
      }
    };
    const { 
      geraDefaultTimes, 
      validarCNPJ, 
      validateEmail,
      validateEmailTag
    } = UltilChanges()
    const { 
      buscarCodgrupo,
      buscarCodmarca,
      buscarConsultor,
      modeloOS,
      buscarCheklist,
      searchDms
    } = useUtilChangesConcessionaria()
    const loadingButton = ref(false)
    const store = useStore();
    const { showTimeAlert } = useAlert();
    const animate = ref(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const router = useRouter();
    const valortogle = ref(true);
    const codgruposOptions = ref<Select[]>([]);
    const codconsultor = ref<Select[]>([]);
    const BuscaDms = ref<SelectDms[] | []>([]);
    const codchecklist = ref<Select[]>([]);
    const codCidades: any = ref([]);
    const codMarcaselect = ref<Select[]>([]);
    const route = useRoute();
    const usaCodEstado = ref([])
    const paramCodConcessionaria = route.params.codConcessionaria
      ? Number(route.params.codConcessionaria)
      : null;
    const peca: any = ref([]);
    const formSize = ref("default");
    const tags: any = ref([]);
    const tagsRevisoes: any = ref([]);
    const tagstipoOSabertura: any = ref([]);
    const tagsOrcamento: any = ref([]);
    const tagChecklist: any = ref([]);
    const camposAdicionaisDmsWordkFlow = ref(false)
    const CamposAdicionaisWorkflowNuvem = ref(false)  
    const codOS= ref<SelectModeloOs[]>([]);
    const inputConexaoBD = ref(false)
    const inputNomeInstancia = ref(false)
    const codSetorVenda = ref(false)
    const inputCodEmpresa = ref(false)
    const inputsUrlEosIntegracao = ref(false)
    const classRequiredCamposIntegracao = ref(true)
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const dms = ref<Array<{ value: number; text: string; imprimirOs: number }>>([]);     
    const horariosFuncionamento = reactive({
      horaAbertura: geraDefaultTimes(),
      horaFechamento: [] as Horario[] | []
    });
    const delayTimer = ref()
    const checkConIntegracao = reactive({
      exist: false,
    })
    const categoryDescriptionOs = ref<ListaCategoriaOS[] | []>([])
    const categoryDescriptionOsLoading = ref(false)
    const hidenItenDistribuidor = ['stellantis'] //distribuidor que ira esconder alguns cards
    //numeração dos cards
    const numberCardsDistribuidor= {
      stellantis: {
        'Dados gerais' : '1',
        'Suporte TI' : '2',
        'Checklist' : '3',
        'Qualidade' : '4',
      }
    }
    const money = {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
    };    
    const usaMultiChecklist:any= ref([]);
    const formConcessionaria = reactive({
      codConcessionaria: null,
      codAtria: "",
      modoOnline: 0,
      senha: "",
      consultorResponsavel: null as number | null,
      codGrupo: "",
      tipoOsDefault: "",
      codMarca: null as number | null,
      responsavel: "",
      nomeFantasia: "",
      razaoSocial: "",
      cnpj: "",
      endereco: "",
      incricaoEstadual:"",
      telefone: "",
      email: "",
      status: 1,
      contabilizarMO: "",
      mostrarPremiacao: "",
      codRevisoesTipo: "",
      nomePacoteFabrica: "",
      nomePacoteBasico: "",
      nomePacoteIntermediario: "",
      nomePacoteRecomendado: "",
      numeroParcelasFabrica: "",
      metaconversao: 50,
      metaticketmedio: "",
      metaProdutos: "",
      diasSegurancaEstoque: 30,
      metaFaturamento: "",
      telefoneSuporte: "",
      emailSuporte: "",
      descontoPecaLiberado: "",
      descontoMoLiberado: "",
      descontoLiberado: "",
      tipoEstoque:"",
      qualidadeVideo: 0,
      qualidadeFoto: 70,
      conintegracao: '',
      bdintegracao: '',
      dms: 0,
      workflow_nuvem:0,
      workflow_marca: "",
      placadms:"",
      osdms: 0,
      estado:"",
      cidade:"",
      nomeConcessionariaDMS:"",
      modeloAgenda: '',
      horaAbertura: 8,
      horaFechamento: 18,
      modelocardapio: 1,
      usachecklist: 0 as number | string | string[] | number[],
      tipoOSabertura:"",
      logo:"",
      intConcessionaria:'',
      codIntMontadora: '',
      categoriaOS: 0,
      tipoCardapio: 1,
      formatoAberturaOS: 1,
      enviaPecasOS: 1,
      leituraVendas: 1,
      abrirPreOrdem: 1,
      precoosagregados: 0,
      codRecomendado: '',
      codIntermediario: '',
      codBasico: '',
      codRevisoes: '',
      desmarcaFabrica: '',
      emailonepage: '',
      acampaatualizacao: '',
      emailsaidachecklist: '',
      buscaplaca: 3,
      emailorcamentochecklist: '',
      emailcopiachecklist: '',
      modeloOs: '',
      emailOs: 0,
      textoLegalOrcamento: '',
      validadeOrcamento: '',
      urlintegracao: '',
      podeEditarValorServico: 0,
      podeEditarValorPeca: 0,
      podeRemoverServico: 0,
      mostrarItemNaoAutorizadoPdf: 0,
      enviarPesquisa: 0
    });
    const isMobile = ref(false);
    const dadosBuscados = ref(false);
    let cropper:any = null;
    const selectedFile:any = ref(null);
    const imageInput:any = ref(null); // template ref for file input
    const img:any = ref(null);

    if(distribuidor == 'stellantis'){
      formConcessionaria.metaProdutos = '0'
      formConcessionaria.metaticketmedio = '0'
    }

    function logicaHora() {      
      const startHour = moment(formConcessionaria.horaAbertura, "HH:mm");
      const auxStartHour = startHour.clone();
      const endHour = moment(formConcessionaria.horaFechamento, "HH:mm");
      horariosFuncionamento.horaFechamento = geraDefaultTimes(auxStartHour.clone().add(1, "hour").format("HH:mm"),"19:00", 60, true);
      if(endHour && endHour.isSameOrBefore(startHour)){
        formConcessionaria.horaFechamento = 18;
      }  
    }      

    const configDms = {
      dealernet: () => {
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.intConcessionaria = ''
        formConcessionaria.categoriaOS = 0
      },
      apollo: () => {     
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.intConcessionaria = ''
        const nomeInstacia = formConcessionaria.bdintegracao
        if(nomeInstacia === '') {
           formConcessionaria.bdintegracao = 'CNP'
        }else if(nomeInstacia === 'NBS'){
          formConcessionaria.bdintegracao = 'CNP'
        }   
      },
       'api-apollo': () => {     
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        inputCodEmpresa.value = false
        codSetorVenda.value = false
        const nomeInstacia = formConcessionaria.bdintegracao
        if(nomeInstacia === '') {
           formConcessionaria.bdintegracao = 'CNP'
        }else if(nomeInstacia === 'NBS'){
          formConcessionaria.bdintegracao = 'CNP'
        }
      },
      nbs: () => {
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.intConcessionaria = ''
        const nomeInstacia = formConcessionaria.bdintegracao
        if(nomeInstacia === '') {
           formConcessionaria.bdintegracao = 'NBS'
        }else if(nomeInstacia === 'CNP'){
          formConcessionaria.bdintegracao = 'NBS'
        }   
      }, 
       'api-nbs': () => {
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        inputCodEmpresa.value = false
        codSetorVenda.value = true
        const nomeInstacia = formConcessionaria.bdintegracao
        if(nomeInstacia === '') {
           formConcessionaria.bdintegracao = 'NBS'
        }else if(nomeInstacia === 'CNP'){
          formConcessionaria.bdintegracao = 'NBS'
        }
      },   
      workflow: () => {        
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        camposAdicionaisDmsWordkFlow.value = true
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.intConcessionaria = ''
        const nomeInstacia = formConcessionaria.bdintegracao  
        if(nomeInstacia === 'NBS') {
           formConcessionaria.bdintegracao = ''
        }else if(nomeInstacia === 'CNP'){
          formConcessionaria.bdintegracao = ''
        }  
       
        if(formConcessionaria.workflow_nuvem == 1) {
          CamposAdicionaisWorkflowNuvem.value = true
          const workFlowMarca = formConcessionaria.workflow_marca
           if(workFlowMarca === '' || workFlowMarca === null ) {

            const  marcaDinamica = () => {
             const marcaSelecionada = codMarcaselect.value.filter(marca => marca.value == formConcessionaria.codMarca)
             formConcessionaria.workflow_marca = marcaSelecionada[0]?.text
            }
            watch(() => formConcessionaria.codMarca, () => {
              marcaDinamica()
            })
            marcaDinamica()            
           }
        }
        else{
          CamposAdicionaisWorkflowNuvem.value = false
        } 
         
      },
      'api-workflow': () => {     
        inputNomeInstancia.value = false
        inputConexaoBD.value = false
        inputsUrlEosIntegracao.value = false
        camposAdicionaisDmsWordkFlow.value = true
        inputCodEmpresa.value = true
        formConcessionaria.intConcessionaria = ''
        codSetorVenda.value = false
        const nomeInstacia = formConcessionaria.bdintegracao  
        if(nomeInstacia === 'NBS') {
           formConcessionaria.bdintegracao = ''
        }else if(nomeInstacia === 'CNP'){
          formConcessionaria.bdintegracao = ''
        }  
       
        if(formConcessionaria.workflow_nuvem == 1) {
          CamposAdicionaisWorkflowNuvem.value = true
          const workFlowMarca = formConcessionaria.workflow_marca
           if(workFlowMarca === '' || workFlowMarca === null ) {

            const  marcaDinamica = () => {
             const marcaSelecionada = codMarcaselect.value.filter(marca => marca.value == formConcessionaria.codMarca)
             formConcessionaria.workflow_marca = marcaSelecionada[0]?.text
            }
            watch(() => formConcessionaria.codMarca, () => {
              marcaDinamica()
            })
            marcaDinamica()            
           }
        }
         
      },
      camp : () => {
        inputConexaoBD.value = true
        inputNomeInstancia.value = true
        inputsUrlEosIntegracao.value = false
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.conintegracao = ''
        formConcessionaria.bdintegracao = ''
        formConcessionaria.intConcessionaria = ''
      },
      outro: () => {
        inputConexaoBD.value = true
        inputNomeInstancia.value = true
        inputsUrlEosIntegracao.value = true
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.intConcessionaria = ''
        formConcessionaria.conintegracao = ''
        formConcessionaria.bdintegracao = ''
        formConcessionaria.osdms = 0
        formConcessionaria.urlintegracao = "https://dms.camptecnologia.com.br/"
        classRequiredCamposIntegracao.value = false
      },
      'nãoinformado': () => {
        inputConexaoBD.value = true
        inputNomeInstancia.value = true
        inputsUrlEosIntegracao.value = true
        inputCodEmpresa.value = true
        codSetorVenda.value = false
        formConcessionaria.conintegracao = ''
        formConcessionaria.bdintegracao = ''
        formConcessionaria.intConcessionaria = ''
        formConcessionaria.osdms = 0
        formConcessionaria.urlintegracao = "https://dms.camptecnologia.com.br/"
        classRequiredCamposIntegracao.value = false
      },
      default : () => {
        inputCodEmpresa.value = true
        inputConexaoBD.value = false
        inputNomeInstancia.value = true
        inputsUrlEosIntegracao.value = false   
        codSetorVenda.value = false
        formConcessionaria.bdintegracao = ''
        formConcessionaria.intConcessionaria = ''
      }
      
    }       

    watch(()=> formConcessionaria.osdms ,()=> {
      if(formConcessionaria.osdms == 0){
          formConcessionaria.modeloOs = '',
          formConcessionaria.emailOs = 0
          formConcessionaria.tipoOSabertura = ''
          tagstipoOSabertura.value = []
      }
    })

    const  statusInputValidado = () =>  {
      const statusInputNomeIntegracao = document.querySelectorAll('.bdintegracao .el-input__suffix')[0]
      const statusInputConexaoBD = document.querySelectorAll('.conintegracao .el-input__suffix')[0]

      const dmsCamposObrigatorios= [1, 2, 3, 7]

      if (dmsCamposObrigatorios.includes(formConcessionaria.dms)) {
        statusInputNomeIntegracao?.classList.remove('status_input')
        statusInputConexaoBD?.classList.remove('status_input')
      } else {
        statusInputNomeIntegracao?.classList.add('status_input')
        statusInputConexaoBD?.classList.add('status_input')
      }     
    }   
    
    const getCategoriaOs = () => {

      if(formConcessionaria.dms == 12 && checkConIntegracao.exist){
        try {
          categoryDescriptionOsLoading.value = true

          const payload = {
            dms: formConcessionaria.dms ?? '',
            cnpj: formConcessionaria.cnpj ?? '',
            con: formConcessionaria.conintegracao ?? ''
          }

          categoriaOs(payload)
            .then((response) =>{
              if (response && response.hasOwnProperty('ListaCategoriaOS')) {
                  categoryDescriptionOs.value =  response.ListaCategoriaOS
              } else {
                categoryDescriptionOs.value = []
                formConcessionaria.categoriaOS = 0
              }
            })
            .finally(() => categoryDescriptionOsLoading.value = false)
          } catch (error) {   
            console.log(error)         
            showTimeAlert("Não foi possível completar a solicitação", "error");
          }
      }     
    }

    
    watch(  () => [formConcessionaria.dms,formConcessionaria.workflow_nuvem], async ()=> {
      classRequiredCamposIntegracao.value = true
      inputsUrlEosIntegracao.value = true

      //verificaçoes da conexão bd
      checkConIntegracao.exist = false

      statusInputValidado()  //tirar o status do input quando for obrigatorio 

      searchConnectionNbs()
      getCategoriaOs()
      
      if(formConcessionaria.dms !== 7 && formConcessionaria.dms !== 10 ){
        camposAdicionaisDmsWordkFlow.value = false
        formConcessionaria.workflow_nuvem = 0 
      }

      if (!dadosBuscados.value) {
        // Verifique se os dados já foram buscados
        await buscarDms();
      }

      const buscaDmsSelecionado = BuscaDms.value.filter(
        (BuscaDms) => BuscaDms.value == formConcessionaria.dms
      ); // pega o nome do DMS
      const textDms = buscaDmsSelecionado[0].text.toLowerCase().replace(" ", "");

      if(configDms[textDms]){
        // dms com validações específicas
        configDms[textDms]();
      }else {
        // dms com validações iguais
        configDms.default();
      }
    })    

    const handleImageCropped = () => {
      const imageResult = cropper
        .getCroppedCanvas({
          width: 256,
          height: 256,
        })
        .toDataURL('image/jpeg');

      imgInicial.value = imageResult;     
      selectedFile.value = null;
    };

    const fileChanged = (e) => {
      const files = e.target.files || e.dataTransfer.files;
      if (files.length) {
        openModalImagem();
        selectedFile.value = files[0];
      }
    };

    const fileCleared = () => {
      imageInput.value.value = null;
      selectedFile.value = null;
    };

    function openModalImagem() {
      const modal = new Modal(document.getElementById("modal-select-concessionaria-foto"), {keyboard: false, backdrop: 'static'});
      modal.show();
    };

    function closeModal() {
      const modal = document.getElementById("modal-select-concessionaria-foto");
      hideModal(modal);
    };    
   
    const rules = ref({
      codAtria: [
        {
          required: true,
          message: "Digite o Código válido do Fornecedor",
          trigger: "blur",
        },
      ], 
      cidade: [
        {
          required: true,
          message: "Selecione uma cidade",
          trigger: "blur",
        },
      ], 
      estado: [
        {
          required: true,
          message: "Selecione um estado",
          trigger: "blur",
        },
      ],     
      consultorResponsavel: [
        {
          required: true,
          message: "Selecione o consultor responsável",
          trigger: "blur",
        },
      ],
      codGrupo: [
        {
          required: true,
          message: "Selecione o grupo vinculado",
          trigger: "blur",
        },
      ],
      tipoOsDefault: [
        {
          required: false,
          message: "Selecione o Tipo da OS padrão",
          trigger: "blur",
        }
      ],
      codMarca: [
        {
          required: true,
          message: "Selecione a marca",
          trigger: "blur",
        },
      ],
      responsavel: [
        {
          required: true,
          message: "Digite o nome do responsável",
          trigger: "blur",
        },
      ],
      nomeFantasia: [
        {
          required: true,
          message: "Digite o nome da concessionária",
          trigger: "blur",
        },
      ],
      razaoSocial: [
        {
          required: true,
          message: "Digite a razão social",
          trigger: "blur",
        },
      ],
      cnpj: [
        {
          validator: validarCNPJ,
          required: true,
          trigger: "blur",
        },
      ],
      senha: [
        {
          required: true,
          message: "Digite senha válida",
          trigger: "blur",
        },
      ],
      endereco: [
        {
          required: true,
          message: "Digite endereço válido",
          trigger: "blur",
        },
      ],
       incricaoEstadual: [
        {
          required: false,
          message: "Digite inscricao Estadual válido",
          trigger: "blur",
        },
      ],
      telefone: [
        {
          required: true,
          message: "Telefone obrigatório",
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: "Email obrigatório ",
          trigger: "blur",
        },
        {
          required: true,
          type: 'email',
          message: "Digite endereço válido",
          trigger: "change",
        },
      ],
      metaticketmedio: [
        {
          required: true,
          message: "Insira uma meta de ticket médio",
          trigger: "blur",
        },
      ],
      metaProdutos: [
        {
          required: true,
          message: "Insira uma meta de produtos médio",
          trigger: "blur",
        },
      ],
      metaFaturamento: [
        {
          required: true,
          message: "Insira uma meta de faturamento médio",
          trigger: "blur",
        },
      ],
      telefoneSuporte: [       
        {
          required: true,
          message: "Telefone obrigatório",
          trigger: "blur",
        },
      ],
      emailSuporte: [
        {
          required: true,
          message: "Email obrigatório",
          trigger: "blur"
        },
        {
          required: true,
          validator: validateEmail,
          trigger: "change",
        },
      ],
      emailonepage: [
        {
          required: false,
          type: 'email',
          message: "Digite email válido", 
          trigger: "change",         
        },
      ],
      emailorcamentochecklist: [
        {
          required: false,
          type: 'email',
          message: "Digite email válido", 
          trigger: "change"
        },
      ],
      emailcopiachecklist: [
        {
          required: false,
          type: 'email',
          message: "Digite email válido", 
          trigger: "change"
        },
      ],
      bdintegracao: [
        {
          validator: (rule: any, value: any, callback: any) => {
            const validarDms = [1,2,3]              
            if(!validarDms.includes(formConcessionaria.dms)) return true
             else if(!value)  return false
            
             return true
          },
          message: "Nome instância obrigatório",
          trigger: 'blur',  
        }
      ],
      conintegracao: [
        {
          validator: (rule: any, value: any, callback: any) => {
            const validarDms = [0,99,8] 

            if(validarDms.includes(formConcessionaria.dms)) return true 
            else if(!value) return false
            
            return true
          },
          message: "Conexão obrigatório",         
          trigger: 'blur',
        }
      ],
      nomeconcessionariaDMS : [
        {
          validator: (rule: any, value: any, callback: any) => {
            if(formConcessionaria.dms != 7) return false 
            
            return true
          },
          message: "Nome concessionaria DMS obrigatório",         
          trigger: 'blur',
        }
      ],
      placadms : [
        {
          required: true,
          message: "Placa dms obrigatório",
          trigger: "blur"
        }
      ],
      workflow_marca :  {
          required: true,
          message: "Marca dms obrigatório",
          trigger: "blur"
        },
      horaAbertura: [
        {
          required: true,
          message: "Horário obrigatório",
          trigger: "blur"
        }
      ],
      horaFechamento: [
        {
          required: true,
          message: "Selecione um horário",
          trigger: "blur"
        }
      ],  
      codIntMontadora: [
        {
          required: true,
          message: "Código obrigatório",
          trigger: "blur"
        }
      ], 
    });
    
    watch(()=>formConcessionaria.dms,()=>{
      const dmsFiltrado = dms.value.filter(item => item.imprimirOs == 1);
      const existeDmsFiltrado = dmsFiltrado.some(item => item.value === formConcessionaria.dms);
      if (!existeDmsFiltrado && dmsFiltrado.length > 0) {
        formConcessionaria.modeloOs = '';
      }
    });

    onMounted(async () => {

      if (paramCodConcessionaria) {
        buscarDms()
       await getConcessionaria(paramCodConcessionaria);
      }else{
        store.commit(Mutations.SET_CONCESSIONARIA_SELECTED, {}) // para limpar a última concessionaria aberta
        formConcessionaria.status = 1 // quando for cadastrar uma concessionaria nova, o toogle de ativo ou desbilitado, sempre ativo
        await getPassword();
      }

      cropper = new Cropper(img.value, {
        aspectRatio: 1,
        viewMode: 2,
        movable: false,
        minContainerWidth: 450,
        minContainerHeight: 450
      });

      logicaHora()
      buscarCodgrupo().then((response) => codgruposOptions.value = response)
      buscarConsultor().then(response => codconsultor.value = response);
      buscarCodmarca().then(response => codMarcaselect.value = response );
      modeloOS().then(response =>  codOS.value = response );
      buscarCheklist(distribuidor).then((response) => codchecklist.value = response);
      
      let userAgent = navigator.userAgent || navigator.vendor;
      if(userAgent.match( /Android/i) || userAgent.match(/iPhone/i)) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
      
      searchConnectionNbs()      
    });

    onUnmounted(() => {
      cropper.destroy();
    });

    //Gerar senha aleatória quando for um cadastro de concessionária nova;
    function getPassword() {
      let chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJLMNOPQRSTUVWXYZ";
      let passwordLength = 8;

      for (let i = 0; i < passwordLength; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        formConcessionaria.senha += chars.substring(randomNumber, randomNumber + 1);
      }
    }
   
    function addEmailOnepage() {
      
      if (formConcessionaria.emailonepage) {
        const arrayEmails = formConcessionaria.emailonepage.replaceAll(" ", "").replaceAll(";", ",").toLowerCase();
        const splitsAdd = arrayEmails.split(",");

        let arrayEmailValidados; 

        const todosEmailValidados = splitsAdd.every((email) => {
          return validateEmailTag(email);
        });

        if (todosEmailValidados) {
          arrayEmailValidados = [...splitsAdd];
        }

        if (arrayEmailValidados) {
          arrayEmailValidados = arrayEmailValidados.filter(email => !tags.value.includes(email))
          if (arrayEmailValidados.length != 0) {
           tags.value = [...tags.value]
           const arraySemEmailDuplicado =  arrayEmailValidados.filter((email, i) => arrayEmailValidados.indexOf(email) === i);
           arraySemEmailDuplicado.forEach(email =>  tags.value.unshift(email.trim()))
          }
          formConcessionaria.emailonepage = ""
          return true;
        }
      }
      return true;
    }

    function addEmailResponsavelOrcamento() {
      if (formConcessionaria.emailorcamentochecklist) {
        const arrayEmails = formConcessionaria.emailorcamentochecklist.replaceAll(" ", "").replaceAll(";", ",").toLowerCase();
        const splitsAdd = arrayEmails.split(",");

        let arrayEmailValidados; 

        const todosEmailValidados = splitsAdd.every((email) => {
          return validateEmailTag(email);
        });

        if (todosEmailValidados) {
          arrayEmailValidados = [...splitsAdd];
        }

        if(arrayEmailValidados){
          arrayEmailValidados = arrayEmailValidados.filter(email => !tagsOrcamento.value.includes(email))            
          if(arrayEmailValidados != 0) {
          tagsOrcamento.value = [...tagsOrcamento.value]
          const arraySemEmailDuplicado =  arrayEmailValidados.filter((email, i) => arrayEmailValidados.indexOf(email) === i);           
          arraySemEmailDuplicado.forEach(email =>  tagsOrcamento.value.unshift(email.trim()))
        }
          formConcessionaria.emailorcamentochecklist = "";
          
          return true;
        }
        
      }
      return true;
    }

    function addEmailCopiaChecklist() {
    if (formConcessionaria.emailcopiachecklist) {
        const arrayEmails = formConcessionaria.emailcopiachecklist.replaceAll(" ", "").replaceAll(";", ",").toLowerCase();
        const splitsAdd = arrayEmails.split(",");

        let arrayEmailValidados; 

        const todosEmailValidados = splitsAdd.every((email) => {
          return validateEmailTag(email);
        });

        if (todosEmailValidados) {
          arrayEmailValidados = [...splitsAdd];
        } 

        if(arrayEmailValidados){
          arrayEmailValidados = arrayEmailValidados.filter(email => !tagChecklist.value.includes(email))
          if(arrayEmailValidados.length != 0) {
            tagChecklist.value = [...tagChecklist.value]
            const arraySemEmailDuplicado =  arrayEmailValidados.filter((email, i) => arrayEmailValidados.indexOf(email) === i);           
            arraySemEmailDuplicado.forEach(email =>  tagChecklist.value.unshift(email.trim()))
          }
          formConcessionaria.emailcopiachecklist = "";
        }       
      }     
    }

    function removetag(index, tag) {
      if (tag === "tags") {
        tags.value.splice(index, 1);
        formConcessionaria.emailonepage = ""
      }
      if (tag === "tagsRevisoes") {
        tagsRevisoes.value.splice(index, 1);
        formConcessionaria.codRevisoes = tagsRevisoes.value.join(",");
        formConcessionaria.codRevisoes = ""
      }
      if (tag === "tagstipoOSabertura") {
        if(tagstipoOSabertura.value[index] === formConcessionaria.tipoOsDefault){
            formConcessionaria.tipoOsDefault = ''
        }
        tagstipoOSabertura.value.splice(index, 1);
        formConcessionaria.tipoOSabertura = tagstipoOSabertura.value.join(",");
        formConcessionaria.tipoOSabertura = ""
      }
      if (tag === "tagChecklist") {
        tagChecklist.value.splice(index, 1);
        formConcessionaria.emailcopiachecklist = tagChecklist.value.join(",");
        formConcessionaria.emailcopiachecklist = ""
      }
      if (tag === "tagsOrcamento") {
        tagsOrcamento.value.splice(index, 1);
        formConcessionaria.emailorcamentochecklist =
        tagsOrcamento.value.join(",");
        formConcessionaria.emailorcamentochecklist = ""
      }
    }

    function addTagCodigoRevisoes() {
      if (formConcessionaria.codRevisoes) {
        if(formConcessionaria.codRevisoes === " ") return formConcessionaria.codRevisoes =  "";   
        const arrayCodRevisao = formConcessionaria.codRevisoes.replaceAll(";", ",")
        const splitAdd = arrayCodRevisao.split(',')
        const arrayTagFiltrada = splitAdd.filter(email => !tagsRevisoes.value.includes(email))
        const arraySemCodRevisaoDuplicado = arrayTagFiltrada.filter((codRevisao, i) => arrayTagFiltrada.indexOf(codRevisao) === i);        
        arraySemCodRevisaoDuplicado.forEach(codRevisao =>  tagsRevisoes.value.unshift(codRevisao.trim()))
        formConcessionaria.codRevisoes = "";
      }
      formConcessionaria.codRevisoes = "";     
    }

     function addTagtipoOSabertura() {
      if (formConcessionaria.tipoOSabertura) {
        if(formConcessionaria.tipoOSabertura === " ") return formConcessionaria.tipoOSabertura =  "";   
        const arrayCodRevisao = formConcessionaria.tipoOSabertura.replaceAll(";", ",")
        const splitAdd = arrayCodRevisao.split(',')
        const arrayTagFiltrada = splitAdd.filter(email => !tagstipoOSabertura.value.includes(email))
        const arraySemCodRevisaoDuplicado = arrayTagFiltrada.filter((codRevisao, i) => arrayTagFiltrada.indexOf(codRevisao) === i);        
        arraySemCodRevisaoDuplicado.forEach(codRevisao =>  tagstipoOSabertura.value.unshift(codRevisao.trim()))
      }
      formConcessionaria.tipoOSabertura = "";     
    }    

    function clearChecklist(){
      usaMultiChecklist.value = [0]    
      formConcessionaria.usachecklist = 0 
    }

    function mudarVariavelStellantis(){
      let idx = usaMultiChecklist.value.indexOf(0);
        if (idx !== -1) {
          usaMultiChecklist.value.splice(idx, 1);
        }

      let arr2 = Object.values(usaMultiChecklist.value).join(";")
      formConcessionaria.usachecklist =[] 
      formConcessionaria.usachecklist = arr2 
    }   
   
    async function getConcessionaria(codConcessionaria){
      animate.value = true;
        let response = await getView(codConcessionaria);
          formConcessionaria.usachecklist = Number(response.usachecklist)
          if( formConcessionaria.usachecklist == 1 && distribuidor == "stellantis" ){
            usaMultiChecklist.value = [0]
          }
          if( formConcessionaria.usachecklist != 1 && distribuidor == "stellantis" ){
            let string  = response.usachecklist
            if(string == null) {
              usaMultiChecklist.value = [0]
            }
            if(string != null){
              usaMultiChecklist.value = string.split(";").map(numero => parseInt(numero));
            }
          }
          formConcessionaria.buscaplaca = response.buscaplaca
          if(response.emailorcamentochecklist){
            let splits = (response.emailorcamentochecklist).split(",");
            tagsOrcamento.value = splits;
          }
          if(response.emailcopiachecklist){
            let splits = (response.emailcopiachecklist).split(",");
            tagChecklist.value = splits;
          }
          formConcessionaria.logo = response.logo;
          if (formConcessionaria.logo) {
            imgInicial.value = formConcessionaria.logo;
          }
          formConcessionaria.emailsaidachecklist = response.emailsaidachecklist
          formConcessionaria.conintegracao = response.conintegracao
          formConcessionaria.bdintegracao = response.bdintegracao
          formConcessionaria.modoOnline = response.modoOnline
          formConcessionaria.acampaatualizacao = response.acampaatualizacao ? moment(response.acampaatualizacao).format("DD/MM/YYYY HH:mm") : ''
          formConcessionaria.urlintegracao = response.urlintegracao
          formConcessionaria.validadeOrcamento = response.validadeOrcamento
          formConcessionaria.tipoOsDefault = response.tipoOsOrcamentoDefault ?? '';
          formConcessionaria.textoLegalOrcamento = response.textoLegalOrcamento
          formConcessionaria.qualidadeVideo = response.qualidadeVideo
          formConcessionaria.qualidadeFoto = response.qualidadeFoto
          formConcessionaria.codConcessionaria = response.codConcessionaria;
          formConcessionaria.precoosagregados = response.precoosagregados
          formConcessionaria.diasSegurancaEstoque = response.diasSegurancaEstoque
          formConcessionaria.codAtria = response.codAtria
          formConcessionaria.senha = response.senha 
          formConcessionaria.consultorResponsavel = response.consultorResponsavel
          formConcessionaria.codGrupo = response.codGrupo
          formConcessionaria.codMarca = response.codMarca
          formConcessionaria.responsavel = response.responsavel
          formConcessionaria.nomeFantasia = response.nomeFantasia
          formConcessionaria.razaoSocial = response.razaoSocial
          formConcessionaria.cnpj = response.cnpj
          formConcessionaria.endereco = response.endereco
          formConcessionaria.incricaoEstadual = response.incricaoEstadual
          formConcessionaria.telefone = response.telefone
          formConcessionaria.email = response.email ?? ''
          formConcessionaria.status = response.status
          formConcessionaria.contabilizarMO = response.contabilizarMO
          formConcessionaria.mostrarPremiacao = response.mostrarPremiacao
          formConcessionaria.codBasico = response.codBasico
          formConcessionaria.codIntermediario = response.codIntermediario
          formConcessionaria.codRecomendado = response.codRecomendado
          formConcessionaria.codIntMontadora = response.codIntMontadora
          formConcessionaria.categoriaOS = response.categoriaOS ?? 0
          
          if(response.codRevisoes){
            let tagrevisoes = (response.codRevisoes).split(",");
            tagsRevisoes.value = tagrevisoes;
          }
          if(response.tipoOSabertura){
            let tagstipoOSabertura2 = (response.tipoOSabertura).split(",");
            tagstipoOSabertura.value = tagstipoOSabertura2;
          }
          formConcessionaria.codRevisoesTipo = response.codRevisoesTipo
          formConcessionaria.desmarcaFabrica = response.desmarcaFabrica
          formConcessionaria.nomePacoteFabrica = response.nomePacoteFabrica
          formConcessionaria.nomePacoteBasico = response.nomePacoteBasico
          formConcessionaria.nomePacoteIntermediario = response.nomePacoteIntermediario
          formConcessionaria.nomePacoteRecomendado = response.nomePacoteRecomendado
          formConcessionaria.numeroParcelasFabrica = response.numeroParcelasFabrica

          if (response.emailonepage){
            let splits = (response.emailonepage).split(",");
            tags.value = splits;
          }
          formConcessionaria.metaconversao = response.metaconversao
          formConcessionaria.metaticketmedio = response.metaticketmedio
          formConcessionaria.metaProdutos = response.metaProdutos
          formConcessionaria.metaFaturamento = response.metaFaturamento
          formConcessionaria.telefoneSuporte = response.telefoneSuporte
          formConcessionaria.emailSuporte = response.emailSuporte
          formConcessionaria.descontoPecaLiberado = response.descontoPecaLiberado
          formConcessionaria.descontoMoLiberado = response.descontoMoLiberado
          formConcessionaria.descontoLiberado = response.descontoLiberado
          formConcessionaria.tipoEstoque = response.tipoEstoque          
          formConcessionaria.osdms = response.osdms
          formConcessionaria.dms = response.dms
          formConcessionaria.workflow_nuvem = response.workflow_nuvem
          formConcessionaria.workflow_marca = response.workflow_marca    
          formConcessionaria.modeloOs = response.modeloOs 
          formConcessionaria.emailOs = response.emailOs 
          formConcessionaria.placadms = response.workflow_placa
          formConcessionaria.cidade = response.cidade
          formConcessionaria.estado = response.estado
          formConcessionaria.nomeConcessionariaDMS = response.nomeConcessionariaDMS
          if( formConcessionaria.estado == '' && formConcessionaria.cidade == ''){
            formConcessionaria.cidade = ''
            formConcessionaria.estado = ''
          }
          if(formConcessionaria.estado && formConcessionaria.cidade){
            cidadeEstado(response.estado)
          }         
          formConcessionaria.horaAbertura = response.horaAbertura
          formConcessionaria.horaFechamento = response.horaFechamento
          formConcessionaria.modeloAgenda = response.modeloAgenda
          formConcessionaria.modelocardapio = response.modelocardapio
          formConcessionaria.intConcessionaria = response.intConcessionaria
          formConcessionaria.tipoCardapio = response.tipoCardapio          
          formConcessionaria.formatoAberturaOS = response.formatoAberturaOS          
          formConcessionaria.enviaPecasOS = response.enviaPecasOS          
          formConcessionaria.leituraVendas = response.leituraVendas          
          formConcessionaria.abrirPreOrdem = response.abrirPreOrdem      
          formConcessionaria.podeEditarValorServico = response.podeEditarValorServico      
          formConcessionaria.podeEditarValorPeca = response.podeEditarValorPeca      
          formConcessionaria.podeRemoverServico = response.podeRemoverServico      
          formConcessionaria.mostrarItemNaoAutorizadoPdf = response.mostrarItemNaoAutorizadoPdf      
          formConcessionaria.enviarPesquisa = response.enviarPesquisa  
    
          animate.value = false;         
    }

    async function buscarDms() {
      BuscaDms.value = await searchDms()
      dms.value = BuscaDms.value
      dadosBuscados.value = true; // Marque como true para indicar que os dados foram buscados
    }   

    async function save(postData, error) {    
      loadingButton.value = true
      getStore(postData).then(function (response) {
        showTimeAlert(`Concessionária ${paramCodConcessionaria ? 'editada' : 'criada'} com sucesso`);
        store.dispatch(Actions.SET_CONCESSIONARIA_LIST) //atualizar a lista de concessionaria no store
        router.push("/cadastros/concessionaria/list");
        loadingButton.value = false
      
      }, function (error) {
        let errorValidacaoRepetido = Object.keys(error.response.data) 
        const inicio: any = document.getElementById('concessionaria-'+ errorValidacaoRepetido);
        showTimeAlert("Não foi possível completar a solicitação", "error");

        if(errorValidacaoRepetido[0] == "codAtria"){
          inicio?.scrollIntoView({  block: "center", inline: "nearest" });
          formConcessionaria.codAtria = ''
          submit()
          showTimeAlert("O Código interno do Fornecedor inserido, ja existe", "error");
        }  
        if(errorValidacaoRepetido[0] == "cnpj"){  
          inicio?.scrollIntoView({  block: "center", inline: "nearest" });
          formConcessionaria.cnpj = ""
          submit()
          showTimeAlert("O CNPJ inserido, ja existe", "error");
        } 
        loadingButton.value = false
      });       
    }

    function limparCidade(meses){
      formConcessionaria.cidade = '';
      cidadeEstado(meses)
    }

    async function cidadeEstado(meses){
      let codigoestado = meses;
      let response = await getcidadeestado(null, codigoestado);
      codCidades.value = response.map((cidades) => {
        const option = {
          text: cidades.nome,
          value: cidades.id.toString(),
        };
        return option;
      });
    }   

    const submit = () => {
      if (!formRef.value) return;

      formRef.value.validate((valid, error) => {
        let errorValidacao = Object.keys(error)       
        if(valid){
         if(usaMultiChecklist.value.length > 0){
            formConcessionaria.usachecklist = usaMultiChecklist.value.join(';')
         }else{
          formConcessionaria.usachecklist = formConcessionaria.usachecklist
         }

          const postData = {
            logo: imgInicial.value,
            usachecklist: formConcessionaria.usachecklist  ? formConcessionaria.usachecklist : 0,
            buscaplaca: formConcessionaria.buscaplaca ? formConcessionaria.buscaplaca : 3,
            emailorcamentochecklist: tagsOrcamento.value && tagsOrcamento.value.length > 0 ? tagsOrcamento.value.join(",") : null,
            emailcopiachecklist:tagChecklist.value && tagChecklist.value.length > 0 ? tagChecklist.value.join(",") : null,
            emailsaidachecklist: formConcessionaria.emailsaidachecklist,
            conintegracao: formConcessionaria.conintegracao ?  formConcessionaria.conintegracao : undefined ,
            dms: formConcessionaria.dms,
            modoOnline: formConcessionaria.modoOnline,
            bdintegracao: formConcessionaria?.bdintegracao ? formConcessionaria?.bdintegracao : undefined,
            urlintegracao: formConcessionaria.urlintegracao ? formConcessionaria.urlintegracao : "https://dms.camptecnologia.com.br/",
            validadeOrcamento: formConcessionaria.validadeOrcamento,
            tipoOsDefault: formConcessionaria.tipoOsDefault != '' ? formConcessionaria.tipoOsDefault : undefined,
            textoLegalOrcamento: formConcessionaria?.textoLegalOrcamento,
            qualidadeVideo: formConcessionaria?.qualidadeVideo ? formConcessionaria.qualidadeVideo : 0 ,
            qualidadeFoto: formConcessionaria?.qualidadeFoto ? formConcessionaria.qualidadeFoto : 70,
            codConcessionaria: formConcessionaria.codConcessionaria,
            precoosagregados: formConcessionaria.precoosagregados ? formConcessionaria.precoosagregados : 0,
            diasSegurancaEstoque: formConcessionaria.diasSegurancaEstoque,
            codAtria: formConcessionaria.codAtria,
            senha: formConcessionaria.senha,
            consultorResponsavel: formConcessionaria.consultorResponsavel,
            codGrupo: formConcessionaria.codGrupo,
            codMarca: formConcessionaria.codMarca,
            responsavel: formConcessionaria.responsavel,
            nomeFantasia: formConcessionaria.nomeFantasia,
            razaoSocial: formConcessionaria.razaoSocial,
            cnpj: formConcessionaria.cnpj,
            endereco: formConcessionaria.endereco,
            incricaoEstadual: formConcessionaria.incricaoEstadual,
            email: formConcessionaria.email,
            status: formConcessionaria.status,
            contabilizarMO: formConcessionaria.contabilizarMO,
            mostrarPremiacao: formConcessionaria.mostrarPremiacao,
            codBasico: formConcessionaria.codBasico,
            codIntermediario: formConcessionaria.codIntermediario,
            codRecomendado: formConcessionaria.codRecomendado,
            codRevisoes: tagsRevisoes.value && tagsRevisoes.value.length > 0 ? tagsRevisoes.value.join(",") : null, 
            tipoOSabertura: tagstipoOSabertura.value && tagstipoOSabertura.value.length > 0 ? tagstipoOSabertura.value.join(",") : null, 
            codRevisoesTipo: formConcessionaria.codRevisoesTipo ? formConcessionaria.codRevisoesTipo : 1,
            desmarcaFabrica: formConcessionaria.desmarcaFabrica ? formConcessionaria.desmarcaFabrica : 0,
            nomePacoteFabrica: formConcessionaria.nomePacoteFabrica ? formConcessionaria.nomePacoteFabrica : 'Fábrica',
            nomePacoteBasico: formConcessionaria.nomePacoteBasico ? formConcessionaria.nomePacoteBasico : 'Básico',
            nomePacoteIntermediario: formConcessionaria.nomePacoteIntermediario ? formConcessionaria.nomePacoteIntermediario : 'Intermediário',
            nomePacoteRecomendado: formConcessionaria.nomePacoteRecomendado ? formConcessionaria.nomePacoteRecomendado : 'Recomendado',
            numeroParcelasFabrica: formConcessionaria.numeroParcelasFabrica ? formConcessionaria.numeroParcelasFabrica : 1,              
            emailonepage: tags.value && tags.value.length > 0 ? tags.value.join(",") : null,          
            metaconversao: formConcessionaria?.metaconversao ? formConcessionaria?.metaconversao : 50 ,
            metaticketmedio: formConcessionaria.metaticketmedio,
            metaProdutos: formConcessionaria.metaProdutos,
            metaFaturamento: formConcessionaria.metaFaturamento.replace('R$ ',''),
            telefone: formConcessionaria.telefone,
            telefoneSuporte: formConcessionaria.telefoneSuporte,
            emailSuporte: formConcessionaria.emailSuporte,
            descontoPecaLiberado: formConcessionaria.descontoPecaLiberado,
            descontoMoLiberado: formConcessionaria.descontoMoLiberado,
            descontoLiberado: formConcessionaria.descontoLiberado,
            tipoEstoque: formConcessionaria.tipoEstoque ? formConcessionaria.tipoEstoque : 1,
            osdms: formConcessionaria.osdms,
            workflow_nuvem: formConcessionaria.workflow_nuvem ? formConcessionaria.workflow_nuvem : 0,
            workflow_marca: formConcessionaria.workflow_nuvem == 1 ? formConcessionaria.workflow_marca :  undefined,
            emailOs: formConcessionaria.emailOs ?? undefined,
            modeloOs: formConcessionaria.modeloOs ?? undefined,
            workflow_placa: formConcessionaria.workflow_nuvem == 1 ? formConcessionaria?.placadms : undefined, 
            cidade: formConcessionaria.cidade,
            estado: formConcessionaria.estado,
            nomeConcessionariaDMS: formConcessionaria.nomeConcessionariaDMS ? formConcessionaria?.nomeConcessionariaDMS : undefined,
            horaAbertura: formConcessionaria.horaAbertura,
            horaFechamento: formConcessionaria.horaFechamento,
            modeloAgenda:  formConcessionaria.modeloAgenda ? formConcessionaria.modeloAgenda : undefined,
            modelocardapio: formConcessionaria.modelocardapio ? formConcessionaria.modelocardapio : 1,
            intConcessionaria : formConcessionaria.intConcessionaria ? formConcessionaria.intConcessionaria : undefined,
            codIntMontadora : formConcessionaria.codIntMontadora ? formConcessionaria.codIntMontadora : undefined,
            categoriaOS : formConcessionaria.categoriaOS !== 0 ?  formConcessionaria.categoriaOS : undefined,
            tipoCardapio : formConcessionaria.tipoCardapio,
            formatoAberturaOS : formConcessionaria.formatoAberturaOS, 
            enviaPecasOS : formConcessionaria.enviaPecasOS, 
            leituraVendas : formConcessionaria.leituraVendas, 
            abrirPreOrdem : formConcessionaria.abrirPreOrdem, 
            podeEditarValorServico : formConcessionaria.podeEditarValorServico, 
            podeEditarValorPeca : formConcessionaria.podeEditarValorPeca, 
            podeRemoverServico : formConcessionaria.podeRemoverServico, 
            mostrarItemNaoAutorizadoPdf : formConcessionaria.mostrarItemNaoAutorizadoPdf,
            enviarPesquisa : formConcessionaria.enviarPesquisa,
          }
          save(postData, error);
          emit("addTask", postData);
        }

        if (!valid) {   
          showTimeAlert("Preencha os campos necessários para prosseguir", 'error');
          const inicio: any = document.getElementById('concessionaria-'+ errorValidacao[0]);
          inicio?.scrollIntoView({  block: "center", inline: "nearest" });
          loadingButton.value = false
        }
      });
    };

    function goTo(url) {
      router.push(url);
    }  

    const changeOsDms = () => {
      formConcessionaria.enviaPecasOS = 1
      formConcessionaria.formatoAberturaOS = 1    
      formConcessionaria.enviaPecasOS = 1    
      formConcessionaria.leituraVendas = 1    
      formConcessionaria.abrirPreOrdem = 1  
    }

    function searchConnection(){
      clearTimeout(delayTimer.value);
      const NBS_API = 12
      if(formConcessionaria.dms == NBS_API){

        delayTimer.value = setTimeout(async () => {
            searchConnectionNbs()             
        }, 500);
      }      
    }

    const searchConnectionNbs = async () => {
      const NBS_API = 12
      if(formConcessionaria.dms == NBS_API && formConcessionaria.conintegracao?.length > 0 ){
        await checkConnection({
          con: formConcessionaria.conintegracao
        }).then((response)=> {
          checkConIntegracao.exist = response.existCon
        })
        .catch((err) => {
          console.log(err)
          showTimeAlert("Não foi possível Buscar a conexão BD! Tente Novamente", "error");
          checkConIntegracao.exist = false
        })
      }      
    }
   
    watchEffect(() => {
      if (selectedFile.value) {
        fileReader.readAsDataURL(selectedFile.value);
      } else {
        imageSrc.value = null;
      }
    });

    watch(
      imageSrc,
      () => {
        if (imageSrc.value) {
          cropper.replace(imageSrc.value);
        }
      },
      {
        flush: 'post', // watch runs after component updates
      }
    );

    watch(() => [checkConIntegracao.exist, formConcessionaria.cnpj], ()=> {
      if(checkConIntegracao.exist){
        getCategoriaOs()
      }
    })

    watch(() => formConcessionaria.conintegracao?.length == 0 ,() => {
      formConcessionaria.categoriaOS == 0
    })

    return {
      limparCidade,
      cidadeEstado,
      getPassword,
      formConcessionaria,
      save,
      goTo,
      rules,
      formRef,
      codgruposOptions,
      codconsultor,
      BuscaDms,
      codMarcaselect,
      submit,
      descontoLiberado,
      money,
      valortogle,
      precoosagregados,
      diasSegurancaEstoque,
      modelosCardapio,
      getConcessionaria,
      animate,
      qualidadeFoto,
      qualidadeVideo,
      urlintegracao,
      dms,
      buscaplaca,
      codchecklist,
      numeroParcelasFabrica,
      paramCodConcessionaria,
      tags,
      removetag,
      addTagCodigoRevisoes,
      peca,
      formSize,
      tagsRevisoes,
      tagstipoOSabertura,
      tagsOrcamento,
      tagChecklist,
      validateEmail,      
      selectOptionsIntegração, 
      selectOptionEmailOS,  
      addEmailOnepage,        
      addEmailResponsavelOrcamento,
      addEmailCopiaChecklist,
      loadingButton,
      camposAdicionaisDmsWordkFlow,
      CamposAdicionaisWorkflowNuvem,
      inputNomeInstancia,
      inputConexaoBD,
      inputsUrlEosIntegracao,
      classRequiredCamposIntegracao,
      codCidades,
      estados,
      usaCodEstado,
      isMobile,
      distribuidor,
      horarios,
      modeloAgenda,
      logicaHora,
      ...toRefs(horariosFuncionamento) ,
      mudarVariavelStellantis,
      usaMultiChecklist,
      clearChecklist,
      addTagtipoOSabertura,
      imgInicial,
      fileChanged,
      handleImageCropped,
      selectedFile,
      imageInput,
      fileCleared,
      img,
      closeModal,
      fileReader,
      imageSrc,
      codOS,
      hidenItenDistribuidor,
      numberCardsDistribuidor,
      inputCodEmpresa,
      codSetorVenda,
      categoryDescriptionOs,
      categoryDescriptionOsLoading,
      cardapio,
      abrirPreOrdemModelos,
      changeOsDms,
      searchConnection,
      checkConIntegracao
    };
  },
});
