const modeloAgenda = [
    {
        text: "Sem quadro",
        value: null
    },
    {
        text: "CampV1",
        value: 'CampV1'
    },
    {
        text: "HyundaiV1",
        value: 'HyundaiV1'
    },
    {
        text: "RenaultV1 (Apenas com integração via Api)",
        value: 'RenaultV1'
    }
];

const horarios = [
    {
        text: "08:00",
        value: 8
    },
    {
        text: "09:00",
        value: 9
    },
    {
        text: "10:00",
        value: 10
    },
    {
        text: "11:00",
        value: 11
    },
    {
        text: "12:00",
        value: 12
    },
    {
        text: "13:00",
        value: 13
    },
    {
        text: "14:00",
        value: 14
    },
    {
        text: "15:00",
        value: 15
    },
    {
        text: "16:00",
        value: 16
    },
    {
        text: "17:00",
        value: 17
    },
    {
        text: "18:00",
        value: 18
    },

]

const estados = [
    {
        text: "Acre",
        value: "1",
    },
    {
        text: "Alagoas",
        value: "2",
    },
    {
        text: "Amapá",
        value: "4",
    },
    {
        text: "Amazonas",
        value: "3",
    },
    {
        text: "Bahia",
        value: "5",
    },
    {
        text: "Ceará",
        value: "6",
    },
    {
        text: "Espírito Santo",
        value: "8",
    },
    {
        text: "Goiás",
        value: "9",
    },
    {
        text: "Maranhão	",
        value: "10",
    },
    {
        text: "Mato Grosso",
        value: "13",
    },
    {
        text: "Mato Grosso do Sul",
        value: "12",
    },
    {
        text: "Minas Gerais",
        value: "11",
    },
    {
        text: "Pará",
        value: "14",
    },
    {
        text: "Paraíba",
        value: "15",
    },
    {
        text: "Paraná",
        value: "18",
    },
    {
        text: "Pernambuco",
        value: "16",
    },
    {
        text: "Piauí",
        value: "17",
    },
    {
        text: "Rio de Janeiro",
        value: "19",
    },
    {
        text: "Rio Grande do Norte",
        value: "20",
    },
    {
        text: "Rio Grande do Sul",
        value: "23",
    },
    {
        text: "Rondônia",
        value: "21",
    },
    {
        text: "Roraima",
        value: "22",
    },
    {
        text: "Santa Catarina",
        value: "24",
    },
    {
        text: "São Paulo",
        value: "26",
    },
    {
        text: "Sergipe",
        value: "25",
    },
    {
        text: "Tocantins",
        value: "27",
    },
    {
        text: "Brasilia",
        value: "7",
    },
]

const diasSegurancaEstoque = [
    {
        text: "0",
        value: 0,
    },
    {
        text: "5",
        value: 5,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "15",
        value: 15,
    },
    {
        text: "20",
        value: 20,
    },
    {
        text: "25",
        value: 25,
    },
    {
        text: "30",
        value: 30,
    },
    {
        text: "35",
        value: 35,
    },
    {
        text: "40",
        value: 40,
    },
    {
        text: "45",
        value: 45,
    },
    {
        text: "50",
        value: 50,
    },
    {
        text: "55",
        value: 55,
    },
    {
        text: "60",
        value: 60,
    },
];

const numeroParcelasFabrica = [
    {
        text: "1",
        value: 1,
    },
    {
        text: "2",
        value: 2,
    },
    {
        text: "3",
        value: 3,
    },
    {
        text: "4",
        value: 4,
    },
    {
        text: "5",
        value: 5,
    },
    {
        text: "6",
        value: 6,
    },
    {
        text: "7",
        value: 7,
    },
    {
        text: "8",
        value: 8,
    },
    {
        text: "9",
        value: 9,
    },
    {
        text: "10",
        value: 10,
    },
    {
        text: "11",
        value: 11,
    },
    {
        text: "12",
        value: 12,
    },
];

const urlintegracao = [
    {
        text: "Link Primário",
        value: "https://dms.camptecnologia.com.br/",
    },
    {
        text: "Link Secundário",
        value: "https://dmssec.camptecnologia.com.br/",
    },
];

const selectOptionsIntegração = [
    {
        text: "Não",
        value: 0,
    },
    {
        text: "Sim",
        value: 1,
    },
]

const selectOptionEmailOS = [
    {
        text: "Não",
        value: 0,
    },
    {
        text: "Sim",
        value: 1,
    },
]

const qualidadeVideo = [
    {
        text: "Baixa",
        value: 0,
    },
    {
        text: "Média",
        value: 1,
    },
    {
        text: "Ótima",
        value: 2,
    },
];

const qualidadeFoto = [
    {
        text: "Baixa",
        value: 30,
    },
    {
        text: "Média",
        value: 50,
    },
    {
        text: "Boa",
        value: 70,
    },
    {
        text: "Ótima",
        value: 100,
    },
];

const descontoLiberado = [
    {
        text: "0%",
        value: 0,
    },
    {
        text: "5%",
        value: 5,
    },
    {
        text: "10%",
        value: 10,
    },
    {
        text: "15%",
        value: 15,
    },
    {
        text: "20%",
        value: 20,
    },
    {
        text: "25%",
        value: 25,
    },
    {
        text: "30%",
        value: 30,
    },
];

const precoosagregados = [
    {
        text: "1 - Somente parcela",
        value: 0,
    },
    {
        text: "2 - Soma total dos agregados + Opc 1",
        value: 1,
    },
    {
        text: "3 - Valor individual dos agregados + Opc 1 e 2",
        value: 2,
    },
    {
        text: "4- Soma total geral + Opc 1 e 2 e 3",
        value: 4,
    },
    {
        text: "5 - Valor individual dos servicos + Opc 1 e 2 e 3",
        value: 3,
    },
    {
        text: "6 - Soma total geral + Opc 5",
        value: 5,
    },
];

const modelosCardapio = [
    {
        text: "Um pacote ao lado do outro",
        value: 1,
        gif: "./crud_concessionaria/modelocardapio1.gif",
        descricao: "Ao abrir o cardápio, os pacotes aparecem um ao lado do outro ao clicar nas setinhas."
    },
    {
        text: "Um pacote por vez",
        value: 2,
        gif: "./crud_concessionaria/modelocardapio2.gif",
        descricao: "Ao abrir o cardápio, os pacotes aparecem um de cada vez ao clicar nas setinhas e ao final aparecem todos os pacotes lado a lado."
    },
    {
        text: "Todos os pacotes de uma vez",
        value: 3,
        gif: "./crud_concessionaria/modelocardapio3.gif",
        descricao: "Ao abrir o cardápio, aparecem todos os pacotes lado a lado sem a existência das setas. Apenas na versão 4.1.6 ou superior (aplicativo)."
    }
];

const abrirPreOrdemModelos = [
    {
        text: "Assine o orçamento e depois abra a OS",
        value: 1,
        gif: "./crud_concessionaria/com_pre_ordem.png",
        descricao: "Antes de abrir uma ordem de serviço, vamos gerar um orçamento, que será a pré-ordem. Em seguida, baixaremos o PDF do mesmo e, logo depois, enviaremos os dados para o DMS."
    },
    {
        text: "Abra a OS e depois assine",
        value: 2,
        gif: "./crud_concessionaria/sem_pre_ordem.png",
        descricao: "Nessa opção, vamos para a abertura da ordem de serviço diretamente, sem gerar a pré-ordem."
    }, 
];

const cardapio = [
    {
        text: "Pacotes Tradicionais",
        value: 1,
        gif: "./crud_concessionaria/com_cardapio.png",
        descricao: "Nessa opção, o cardápio estará disponível normalmente."
    },
    {
        text: "Menu Seller",
        value: 2,
        gif: "./crud_concessionaria/sem_cardapio.png",
        descricao: "Nessa opção, o cardápio estará desabilitado inicialmente; para que a funcionalidade seja ativada, é necessário selecionar os itens de fábrica no cardápio."
    }
];

const buscaplaca = [
    {
        text: "Integrado",
        value: 1,
    },
    {
        text: "Não integrado",
        value: 3,
    },
];

export {
    modeloAgenda,
    horarios,
    estados,
    diasSegurancaEstoque,
    numeroParcelasFabrica,
    urlintegracao,
    buscaplaca,
    cardapio,
    modelosCardapio,
    precoosagregados,
    descontoLiberado,
    qualidadeFoto,
    qualidadeVideo,
    selectOptionEmailOS,
    selectOptionsIntegração,
    abrirPreOrdemModelos
}