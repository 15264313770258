import { checklistselect, codgruposelect, consultorSelect, getDms, getmodeloOS, marcaselect } from "@/services/Concessionaria";


export default function useUtilChangesConcessionaria() {

    async function buscarCodgrupo() {

        const response = await codgruposelect();
        return response.map((codgrupo) => {
            const option = {
                text: codgrupo.nome,
                value: codgrupo.codGrupo,
            };
            return option;
        });
    }

    async function buscarCodmarca() {

        const response = await marcaselect();
        return response.map((codMarcaselect) => {
            const option = {
                text: codMarcaselect.nome,
                value: codMarcaselect.codMarca,
            };
            return option;
        });
    }

    async function buscarConsultor() {
        const response = await consultorSelect();

        return response.map((codconsultor) => {
            const option = {
                text: codconsultor.nome,
                value: codconsultor.codUsuario,
            };
            return option;
        });
    }

    async function modeloOS() {

        const response = await getmodeloOS();
        return response.map((codOS) => {
            const option = {
                text: codOS.descricaoOS,
                value: codOS.modeloOS,
            };
            return option;
        });
    }

    async function buscarCheklist(distribuidor) {

        const posTdata = { 'distribuidor': distribuidor }
        const response = await checklistselect(posTdata);
        return response.map((codchecklist) => {
            const option = {
                text: codchecklist.nome,
                value: codchecklist.checklist,
            };
            return option;
        });
    }

    async function searchDms() {

        const response = await getDms();
        return response.map((codDms) => {
            const option = {
                text: codDms.nome,
                value: codDms.codDms,
                imprimirOs: codDms.imprimirOs,
            };
            return option;
        });
    }

    return {
        buscarCodgrupo,
        buscarCodmarca,
        buscarConsultor,
        modeloOS,
        buscarCheklist,
        searchDms
    }
}

